/* 변수 정의 */
:root {
   /* --baseFontColor: #ffffffaa; */
   --hyperlinkColor: #0077cc;
   /* --buttonBgColor: #ffffffaa; */
}

body {
   /* padding: 0px;
   margin: 0px; */
   /* text-align: center; */
   /* display: flex;
   justify-content: center;
   overflow: hidden; */
   /* font-family: 'Press Start 2P'; */
   /* background-color: #0077cc; */
}

/* 배경 이미지 */
.bg {
   background-color: red;
}

/* 하이퍼텍스트 스타일 정의 */
a,
a:focus,
a:active {
   text-decoration: none;
   color: var(--hyperlinkColor);
}
a:hover {
   text-decoration: underline;
}


.blur {
   -webkit-filter: blur(5px);
   -moz-filter: blur(5px);
   -o-filter: blur(5px);
   -ms-filter: blur(5px);
   filter: blur(5px);
   /* z-index: -1; */
}

.bg_loading {
   background: 'linear-gradient(to bottom, #ffbbdd 0px, #fff 200px)';
   /* background-color: brown; */
}

.bg_normal {
   background: 'linear-gradient(to bottom, #bbddff 0px, #fff 200px)';
   /* background-color: brown; */
}

.title {
   /* background-color: gray; */
   font-weight: bold;
   font-size: 38px;
   /* animation: aniZoomIn 0.3s ease-in-out; */
   /* 텍스트 그래디언트 */
   background: linear-gradient(to top, #ffffff, #0999ff);
   -webkit-background-clip: text;
   background-clip: text;
   -webkit-text-fill-color: transparent;
}

@keyframes aniSlideRight {
   0% {
      margin-left: 100%;
      width: 300%;
   }

   100% {
      margin-left: 0%;
      width: 100%;
   }
}
@keyframes aniZoomIn {
   0% {
      transform: scaleY(0);
   }
   70% {
      transform: scaleY(1.2);
   }
   100% {
      transform: scaleY(1);
   }
}

@keyframes aniFadeIn {
   from {
       opacity: 0;
   }
   to {
       opacity: 1;
   }
}