/* 변수 정의 */
:root {
   /* --baseFontColor: #ffffffaa; */
   --hyperlinkColor: #0077cc;
   /* --buttonBgColor: #ffffffaa; */
}

body {
   overflow: hidden;
}

/* 배경 이미지 */
/* .bg {
   background-color: red;
} */

/* 하이퍼텍스트 스타일 정의 */
a,
a:focus,
a:active {
   text-decoration: none;
   color: var(--hyperlinkColor);
}
a:hover {
   text-decoration: underline;
}

.blur {
   -webkit-filter: blur(5px);
   -moz-filter: blur(5px);
   -o-filter: blur(5px);
   -ms-filter: blur(5px);
   filter: blur(5px);
   /* z-index: -1; */
}

.title {
   /* background-color: gray; */
   font-weight: bold;
   font-size: 38px;
   /* animation: aniZoomIn 0.3s ease-in-out; */
   /* 텍스트 그래디언트 */
   background: linear-gradient(to top, #ffffff, #0999ff);
   -webkit-background-clip: text;
   background-clip: text;
   -webkit-text-fill-color: transparent;
}

.heartBeat {
   animation: aniHeartBeat 1s ease-in-out infinite;
}

.zoomOut {
   animation: aniZoomOut 0.3s ease-in-out;
}

.zoomIn {
   animation: aniZoomIn 0.3s ease-in-out;
}

.zoomInY {
   animation: aniZoomInY 0.3s ease-in-out;
}

.fromLeft {
   animation: aniFromLeft 0.3s ease-in-out;
}

.toLeft {
   translate: -100%;
   animation: aniToLeft 0.3s ease-in-out;
}

.fromRight {
   animation: aniFromRight 0.3s ease-in-out;
}

.toRight {
   translate: 100%;
   animation: aniToRight 0.3s ease-in-out;
}

.button {
   cursor: pointer;
}

.button:hover {
   transform: scale(1.03);
}

@keyframes aniFromLeft {
   0% {
      translate: -100%;
   }

   100% {
      translate: 0%;
   }
}

@keyframes aniToLeft {
   0% {
      translate: 0%;
   }

   100% {
      translate: -100%;
   }
}

@keyframes aniFromRight {
   0% {
      translate: 100%;
   }

   100% {
      translate: 0%;
   }
}

@keyframes aniToRight {
   0% {
      translate: 0%;
   }

   100% {
      translate: 100%;
   }
}

@keyframes aniZoomIn {
   0% {
      transform: scale(0);
   }
   100% {
      transform: scale(1);
   }
}

@keyframes aniZoomInY {
   0% {
      transform: scaleY(0);
   }
   100% {
      transform: scaleY(1);
   }
}

@keyframes aniZoomOut {
   0% {
      transform: scale(2);
   }
   100% {
      transform: scale(1);
   }
}

@keyframes aniFadeIn {
   from {
      opacity: 0;
   }
   to {
      opacity: 1;
   }
}

@keyframes aniHeartBeat {
   0% {
      transform: scale(1);
   }
   50% {
      transform: scale(1.05);
   }
   100% {
      transform: scale(1);
   }
}
